import {Mesh} from "three";

import {EffectPass, GodRaysEffect, SMAAEffect, SMAAPreset} from "postprocessing";

export function createPostProcessingEffectStack(camera, lightPanes: Mesh[]) {

    const smaaEffect = new SMAAEffect({
        preset: SMAAPreset.HIGH,
    });

    const godRaysEffects = lightPanes.map(mesh => {
        return new GodRaysEffect(camera, mesh, {
            height:   720,
            density:  1,
            exposure: 0.25,
            blur:     0.1 as any,
        });
    });

    return {
        effectPass: new EffectPass(camera,
            smaaEffect,
            ...godRaysEffects,
        ),
        godRaysEffects,
    };
}