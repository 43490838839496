import {randRange} from "./randRange";

/**
 * Simulates a "flicker" effect, with a minimum and maximum interval
 * between each such flicker and likewise for the length of the flicker itself.
 * For the duration of the effect, the return value will drop from 1 to 0.
 * @param minInterval
 * @param maxInterval
 * @param minLength
 * @param maxLength
 */
export const flicker = (
    minInterval: number,
    maxInterval: number,
    minLength: number,
    maxLength: number,
): ((time: number) => number) => {

    // Time until the 'start' and the 'end' of the next 'flicker'
    let start = 0, end;

    const update = () => {
        start += randRange(minInterval, maxInterval);
        end = start + randRange(minLength, maxLength);
    };

    update();

    return time => {
        start -= time;
        end -= time;
        const flick = start < 0 && end >= 0;
        if (end < 0) {
            update();
        }
        return flick ? 0 : 1;
    };
};