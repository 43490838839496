import * as THREE from 'three';
import {EffectComposer, RenderPass} from "postprocessing";

import {seek} from "./seek.mjs";
import {trackMouse} from "./trackMouse.mjs";
import {loop} from "./loop.mjs";
import {baseInit} from "./baseInit";
import {createPostProcessingEffectStack} from "./postProcessing";
import {revealVersion} from "./revealVersion";
import {flicker} from "./flicker";
// import {getScrollYDelta} from "./getScrollYDelta";
import {importAllStl} from "./importModelStl";
import {tagSocialMediaMenuItems} from "./tagSocialMediaMenuItems";
// import {sigmoid} from "./math";
// import {realInnerHeight} from "./realInnerHeight";

if (/netlify|localhost/.test(location.host)) {
    revealVersion();
}

const {camera, size, renderer} = baseInit();

const scene = new THREE.Scene();

const updateCameraPos = () => {
    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = 3 + Math.max((size.height / size.width) * 3, 1);
    camera.lookAt(0, 0, 0);
};

updateCameraPos();

const light = new THREE.PointLight(0xADE6FC, .4, 100);
light.position.set(0, 0, .8);
scene.add(light);

const mouse = trackMouse();

const seekX = seek(0, .97);
const seekY = seek(0, .97);

const slowSeekX = seek(0, .995);
const slowSeekY = seek(0, .995);

let totalTime = 0;

const initSeed = Math.random() * 1000;

(async () => {

    const letters = ['x', 'e', 'n', 'o'];

    const models = await importAllStl(['mask-xeno', ...letters]);

    const maskMaterial = new THREE.MeshStandardMaterial({color: 0xeeeeee});

    const xenoMaskMesh = new THREE.Mesh(models['mask-xeno'], maskMaterial);
    xenoMaskMesh.scale.multiplyScalar(1.2);
    scene.add(xenoMaskMesh);

    // Add light emitters for each letter such that we can
    // manipulate their brightness and colors individually
    const lightPanes = letters.map(model => {
        const geometry = models[model];
        const material = new THREE.MeshBasicMaterial({color: 0xccccff});
        const mesh     = new THREE.Mesh(geometry, material);
        mesh.scale.multiplyScalar(1.2);
        mesh.position.z = -.1;
        scene.add(mesh);
        const stepFlicker = flicker(50, 8000, 40, 200);
        return {mesh, stepFlicker};
    });

    const composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));
    const {effectPass, godRaysEffects} = createPostProcessingEffectStack(camera, lightPanes.map(lp => lp.mesh));
    composer.addPass(effectPass);

    loop(time => {

        totalTime += time;

        const randTime = initSeed + totalTime;

        updateCameraPos();

        // const windowRatioEnhancer = (((realInnerHeight / window.innerWidth) - 1) * .7) + 1;

        const targetXPos = (mouse.x - size.width / 2) / size.width / 2;
        const targetYPos = (mouse.y - size.height / 2) / size.height / 3;
        // + (sigmoid(-getScrollYDelta() / 2) - .5) * 4 * windowRatioEnhancer;

        const x = seekX(targetXPos, time);
        const y = seekY(targetYPos, time);

        const slowX = slowSeekX(targetXPos, time);
        const slowY = slowSeekY(targetYPos, time);

        godRaysEffects.forEach((godRaysEffect, i) => {

            const godRaysOn = lightPanes[i].stepFlicker(time);

            godRaysEffect.godRaysMaterial.density = (.9 + Math.cos(randTime / 750) * .1);

            godRaysEffect.godRaysMaterial.lightPosition.y = slowY * 3;

            const paneMesh = lightPanes[i].mesh;

            paneMesh.position.x = (x * .3);
            paneMesh.position.y = (-y * .3);

            const intensity = godRaysOn ? 1 : .1;

            paneMesh.material.color = new THREE.Color(
                0.7 + (Math.cos((randTime + i * 900) / 1000)) * .3,
                0.8 + (Math.cos((randTime + i * 900) / 1500)) * .3,
                1,
            ).multiplyScalar(intensity)
        });

        light.position.x = -x * .2;
        light.position.y = y * .2;

        camera.position.x = slowX;
        camera.position.y = -slowY * 3;

        camera.lookAt(0, 0, 0);

        composer.render();
    });
})();

tagSocialMediaMenuItems();