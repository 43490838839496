/**
 * Returns an object that tracks the global mouse position using "mousemove"
 */
import {realInnerHeight} from "./realInnerHeight";

export function trackMouse() {
    const mouse = {
        x: window.innerWidth / 2,
        y: realInnerHeight / 2,
    };
    document.addEventListener('mousemove', (event) => {
        mouse.x = event.clientX;
        mouse.y = event.clientY;
    }, false);
    return mouse;
}