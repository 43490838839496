import {STLLoader} from "three/examples/jsm/loaders/STLLoader";
import {BufferGeometry} from "three";
import {getTagConfig} from "./getTagConfig";

/**
 * @param name Filename in `models` directory without .stl
 */
export async function importModelStl(name: string): Promise<BufferGeometry> {
    return await new Promise(resolve => {
        const loader    = new STLLoader();
        const assetRoot = getTagConfig('asset-root', './');
        loader.load(`${assetRoot}models/${name}.stl`, geometry => {
            resolve(geometry);
        });
    });
}

/**
 * Import all stl files in `models` directory, returning a dictionary where
 * the key is the name of the model and the value is the geometry object
 */
export async function importAllStl(names: string[]): Promise<Record<string, BufferGeometry>> {
    const models = await Promise.all(names.map(name => importModelStl(name)));
    return names.reduce((dict, name, i) => ({...dict, [name]: models[i]}), {});
}