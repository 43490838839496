import * as $ from 'jquery';
import {commitRef} from "./commitRef.mjs";

/**
 * Add a fixed position div tag with the commit ref (version) to the body
 */
export const revealVersion = () => {
    $(`<div
         style="
             position:fixed;
             right: 5px;
             top: 5px; 
             font-family: monospace; 
             color: white; 
             size: 11px; 
             opacity: .7;
         "
    >${commitRef.substring(0, 8)}</div>`).appendTo('body');
};