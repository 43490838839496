const _tagConfigCache = {};

/**
 * "Tag configuration" can be used to supply parameters to the "embed" by adding `data-...` tags
 * on the actual script tag that is used to reference the javascript bundle. This way, different
 * pages can for example use different "brightness" values without having to reference more than
 * one frontend bundle and all configuration stays neatly in one place.
 */
export function getTagConfig(key: string, def: string = ''): string {
    if (!(key in _tagConfigCache)) {
        const attribute      = `data-${key}`;
        const scriptTag      = document.querySelector(`script[${attribute}]`);
        _tagConfigCache[key] = scriptTag?.getAttribute(attribute) ?? def;
    }
    return _tagConfigCache[key];
}