import * as THREE from "three";
import {PerspectiveCamera, WebGLRenderer} from "three";

import {keepFixed, keepFullscreen} from "./responsive";
import {realInnerHeight} from "./realInnerHeight";
import {getTagConfig} from "./getTagConfig";
import {addClickableBox} from "./addClickableBox";

/**
 * Set up a basic render and camera, and an absolutely positioned canvas. This will
 * also set up event listeners to keep the canvas positioned and sized properly.
 */
export function baseInit(): {
    camera: PerspectiveCamera;
    renderer: WebGLRenderer;
    size: { width: number; height: number };
} {

    const size   = {
        width:  window.innerWidth,
        height: realInnerHeight,
    };
    const camera = new THREE.PerspectiveCamera(75, size.width / size.height, 0.1, 1000);

    const pixelRatio = Math.min(1.5, window.devicePixelRatio);

    const renderer = new THREE.WebGLRenderer({});
    renderer.setSize(size.width, size.height);
    renderer.setPixelRatio(pixelRatio);

    const canvas = renderer.domElement;
    canvas.classList.add('floating-canvas');
    document.body.appendChild(canvas);

    const brightness = getTagConfig('brightness', '100%');
    if (brightness !== '100%' && /^\s*\d+%\s*$/.test(brightness)) {
        const opacity        = Number(brightness.trim().slice(0, -1)) / 100;
        canvas.style.opacity = opacity.toString();
    }

    renderer.autoClear = false;
    renderer.setClearColor(0x000000, 1);

    keepFullscreen(renderer, camera, pixelRatio, size);
    keepFixed(canvas);

    const logoTargetHref = getTagConfig('logo-target-href');

    if (logoTargetHref) {
        keepFixed(addClickableBox(canvas, logoTargetHref));
    }

    return {
        camera,
        renderer,
        size,
    };
}