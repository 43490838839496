import {PerspectiveCamera, Renderer} from "three";
import {throttle} from "lodash";

import {supportsFixed} from "./supportsFixed";
import {realInnerHeight} from "./realInnerHeight";

/**
 * Keep a 3D canvas in sync with the browser window size. The `size` parameter
 * should receive a reference to an object of which the `width` and `height`
 * attributes will be updated periodically.
 */
export const keepFullscreen = (
    renderer: Renderer,
    camera: PerspectiveCamera,
    pixelRatio: number,
    size: { width: number; height: number },
) => {

    const canvas = renderer.domElement;

    const syncSize = () => {
        size.width       = window.innerWidth;
        size.height      = realInnerHeight;
        const needResize = (canvas.width / pixelRatio | 0) !== size.width
            || (canvas.height / pixelRatio | 0) !== size.height;
        if (needResize) {
            renderer.setSize(size.width, size.height, true);
            camera.aspect = canvas.clientWidth / canvas.clientHeight;
            camera.updateProjectionMatrix();
        }
    };

    window.addEventListener('resize', throttle(syncSize, 400, {leading: true, trailing: true}));
}

/**
 * Fix the element to the top of the page by using either the CSS attribute
 * or a fallback (sync to the `scrollY` on the `scroll` event)
 * @param element
 */
export const keepFixed = (element: HTMLElement) => {
    if (supportsFixed()) {
        element.style.position = 'fixed';
    } else {
        const syncPosition = () => {
            element.style.top = `${window.scrollY}px`;
        };
        window.addEventListener('scroll', syncPosition);
    }
}