import slugify from "slugify";

/**
 * Find all elements on the page matching the given selector, then append a class to
 * each of the elements based on the "sluggified" text content of each element with
 * the given prefix. E.g. `<div class="menu-item">About us</div>` would receive an
 * additional `item-about-us` class using `tagElements('.menu-item', 'item-');`.
 */
export function tagElements(
    selector: string,
    prefix: string,
    onTag?: (element: Element, slug: string) => void,
): void {
    const elements = document.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const text    = element.textContent;
        const slug    = slugify(text.toLowerCase());
        element.classList.add(`${prefix}${slug}`);
        onTag?.(element, slug);
    }
}