/**
 * Run the given function for each animation frame.
 * The function will be called with the elapsed time since the last iteration.
 */
export function loop(fn) {

    let lastDate = Date.now();

    function animate() {
        requestAnimationFrame(animate);

        const newDate = Date.now();
        const time    = newDate - lastDate;

        fn(time);

        lastDate = newDate;
    }

    animate();
}