import {tagElements} from "./common/tagElements";
import * as jQuery from "jquery";

// Apply classname tags to menu items such that we can replace the text content with social media icons using CSS
export function tagSocialMediaMenuItems() {

    const tag = () => tagElements(
        '.wsite-menu-item',
        'menu-item-',
        (element, slug) => {
            (element.parentNode as HTMLElement)?.classList?.add(
                'tagged-menu-item-parent',
                `tagged-menu-item-parent-${slug}`,
            );
        }
    );

    tag();

    jQuery(function () {
        // Run the tag routine again on complete page load just to be sure
        tag();
    });
}