/**
 * Returns a function that given some next value / target, "pulls" the value towards the target in
 * some amount of time. The "state" is kept internally and the function returns the current value.
 */
export function seek(initial, dampen) {
    let current = initial;
    return (next, interval) => {
        const pull = 1 - Math.pow(dampen, interval);
        current    = (next * pull) + (current * (1 - pull));
        return current;
    };
}